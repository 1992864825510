import { ChangeEvent } from "react"
import parse from 'html-react-parser';

export const CasesTable = (props:any) => {
  const checkedHanlder = (e: ChangeEvent) => {
    if (e.target instanceof HTMLInputElement) {
      if (e.target.checked) {
        props.setSelectedData([...props.selectedData, e.target.value])
        e.target.parentNode?.parentElement?.parentElement?.classList.add('stripped')
      } else {
        const value = e.target.value
        props.setSelectedData(props.selectedData.filter((item:string)=>{return item !== value}))
        e.target.parentNode?.parentElement?.parentElement?.classList.remove('stripped')
      }
    }
  }
  return(
    <table className="centered responsive-table" key={props.cas}>
        <thead>
          <tr className="grey lighten-4">
            <th colSpan={6}>
              <div style={{display: "flex", flexDirection: 'column'}}>
                <div>{props.cas.cas}</div>
              </div>
            </th>
          </tr>
          <tr className="grey lighten-4">
              <th style={{width: '5%'}}></th>
              <th style={{width: '15%'}}>SKU</th>
              <th style={{width: '30%'}}>Item</th>
              <th style={{width: '20%'}}>Molecular Formula</th>
              <th>Purity</th>
              <th style={{width: '10%'}}>Manufacturer</th>
          </tr>
        </thead>

        <tbody>
          {
            props.cas.products.length === 0 && <tr><th colSpan={6} className="center-align">Данных по касу "{props.cas.cas}" не найдено</th></tr>
          }
          {
            props.cas.products.map((product: any)=>(
              <tr key={product.sku} className={props.selectedData.includes(product.sku.toLocaleLowerCase()) ? 'stripped' : ''}>
                <td>
                  <label>
                    <input
                      className="filled-in"
                      type="checkbox"
                      checked={props.selectedData.includes(product.sku.toLocaleLowerCase())}
                      onChange={checkedHanlder}
                      value={product.sku.toLocaleLowerCase()}
                    />
                    <span style={{ transform: 'scale(1.2)'}}></span>
                  </label>
                </td>
                <td>{product.sku}</td>
                <td>{product.item}</td>
                <td>{parse(product.formula)}</td>
                <td>{product.purity}</td>
                <td>{product.manufacturer}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
  )
}
