import { useState, useCallback, useEffect } from "react"
import  jwt_decode from "jwt-decode"

const storageName = 'UserData'

export const useAuth = () => {
  const [token, setToken] = useState(null)

  const login = useCallback((jwt:null) => {
    setToken(jwt)

    localStorage.setItem(storageName, JSON.stringify({token: jwt}))
  }, [])

  const logout = useCallback(()=>{
    setToken(null)

    localStorage.removeItem(storageName)
  }, [])

  useEffect(()=> {
    const data =  JSON.parse(localStorage.getItem(storageName) || '{}')
    if (data && data.token) {
      const decodedToken:any = jwt_decode(data.token)
      if (new Date() > new Date(decodedToken.exp * 1000)) return logout()

      login(data.token)
    }
  }, [login, logout])

  return {login, logout, token}
}