import React from 'react'
import { Routes, Route, Navigate} from 'react-router-dom'
import { ArticlesPage } from './pages/articlesPage'
import { CasesPage } from './pages/casesPage'
import { AuthPage } from './pages/authPage'
import { useAuth } from './hooks/auth.hook'
import { AuthContext } from './context/authContext'
import { NavBar } from "./components/navBar"
import { NavLink } from "react-router-dom"

import 'materialize-css';

function App() {
  const {login, logout, token} = useAuth();
  const isAuth = !!token

  return (
    <AuthContext.Provider value={{token, isAuth, login, logout}}>
      {isAuth && <NavBar />}
      {isAuth &&
        <div className='customTabs' style={{display: 'flex', justifyContent: 'center'}}>
          <NavLink className="black-text center-align" style={{padding: '1.25em', width: '10em'}} to="/cases"><b>Касы</b></NavLink>
          <NavLink className="black-text center-align" style={{padding: '1.25em', width: '10em'}} to="/"><b>Артикулы</b></NavLink>
        </div>
      }
      { (isAuth) ?
        <Routes>
          <Route path="/" element={<ArticlesPage />} />
          <Route path="/cases" element={<CasesPage />} />
          <Route path='/*' element={<Navigate to="/" />}></Route>
        </Routes>
      :
        <Routes>
          <Route path="/" element={<AuthPage />} />
          <Route path='/*' element={<Navigate to="/" />}></Route>
        </Routes>
      }
    </AuthContext.Provider>
  )
}

export default App
