import React, { useContext, useState } from "react";
import { useHttp } from "../hooks/http.hook";
import { AuthContext } from "../context/authContext";

export const AuthPage = () => {
  const auth = useContext(AuthContext)
  const {request, error, clearError} = useHttp()

  const [form, setForm] = useState({
    login: '', password: ''
  })

  const changeHandler = (e: any) => {
    clearError()
    setForm({...form, [e.target?.name]: e.target?.value})
  }

  const loginHanlder = async () =>  {
    try {
      const data = await request('/auth/login', "POST", {...form})
      auth.login(data.token)
    } catch (e) {}
  }

  return (
    <div className="auth-page">
      <div className="container">
        <div className="auth-page__form">
          <h3>Войдите в систему</h3>
          {
            error && <div className="red-text center-align">{error}</div>
          }
          <div className="input-field">
            <input
              type="text"
              name="login"
              className="validate"
              placeholder="Введите логин"
              onChange={changeHandler}
              value={form.login}
            />
          </div>
          <div className="input-field">
            <input
              name="password"
              type="password"
              className="validate"
              placeholder="Введите пароль"
              onChange={changeHandler}
              value={form.password}
            />
          </div>
          <button className="waves-effect waves-light btn" onClick={loginHanlder}>Войти</button>
        </div>
      </div>
    </div>
  )
}