import { createContext } from "react";

function noop(jwt:any){}
function noop2(){}

export const AuthContext = createContext({
  token: null,
  isAuth: false,
  login:  noop,
  logout: noop2
})