import { useContext } from "react"
import { AuthContext } from "../context/authContext"
import { NavLink } from "react-router-dom"

export const NavBar = () => {
  const auth = useContext(AuthContext)

  const logoutHandler = (event: any) => {
    event?.preventDefault()
    auth.logout()
  }

  return(
    <nav>
      <div className="nav-wrapper grey lighten-4" style={{paddingLeft: '2em', paddingRight: '2em'}}>
        <ul className="left black-text">
          <li>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <img src="../images/logo.svg" alt="" style={{width: '3em', marginRight: '1em'}} />
              <span style={{fontSize: '2em' }}>Quick Request Project</span>
            </div>
          </li>
        </ul>
        <ul className="right">
          <li><NavLink className="black-text" to="/" onClick={logoutHandler}>Выход</NavLink></li>
        </ul>
      </div>
    </nav>
  )
}

