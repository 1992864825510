import { useState, useCallback } from "react"

export const useHttp = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  const request = useCallback(async (url: RequestInfo, method = "GET", body:any=null, headers = {}) => {
    if (Object.keys(headers).length === 0){
      headers = {'Accept': 'application/json', 'Content-Type': 'application/json'}
    }

    setLoading(true)
    try {
      const response = await fetch(url, {method, body: JSON.stringify(body), headers})
      const data = await response.json()

      if (!response.ok) {
        throw new Error(data.message || data.errors.errors.map((error:{msg: String})=>error.msg).join(" и "))
      }

      setLoading(false)
      return data
    } catch (e: any) {
      setLoading(false)
      setError(e.message)
      throw e
    }
  }, [])

  const clearError = () => setError(null)

  return {loading, request, error, clearError}
}