import { utils, writeFileXLSX } from 'xlsx';

interface productInterface {
  sku: string,
  item: string,
  purity: string,
  leadtime: string,
  cas: string,
  manufacturer: string,
  quantity: string,
  unit: string,
  shipping_temp: string,
  price: number,
  price_unit: string,
  danger: boolean
}

const unit = (unit: string) => {
  return unit !== '-' ? unit : ''
}

const shipping_temp = (shipping_temp: string) => {
  return shipping_temp !== '-' ? shipping_temp : ''
}

const cas = (cas: string) => {
  return cas !== '-' ? cas : ''
}

const purity = (purity: string) => {
  return purity !== '-' ? purity : ''
}

const leadtime_converter = (leadtime: string, manufacturer: string) => {
  if (manufacturer === "Sigma" && leadtime === 'In Stock') return 'срок поставки - 2 месяца'
  if (manufacturer === "CST" && leadtime === 'Sufficient stock') return 'срок поставки - 1.5 месяца'
  if (['Abclonal', 'MedChemExpress'].includes(manufacturer) && leadtime === 'In Stock') return 'срок поставки - 1.5 месяца'
  if (!["Macklin", "Sigma", "Acmec", "Aladdin", "Abcam", "Synthware", "Thermofisher"].includes(manufacturer)) return leadtime
  if (leadtime === 'In Stock') return 'срок поставки - 1.5 месяца'

  return ''
}

const quantity = (quantity: string) => {
  return quantity !== '-' ? quantity : ''
}

const lead_time = (leadtime : string) => {
  return leadtime !== '-' ? leadtime : ''
}

const check_is_cold = (shipping_temp: string) => {
  try {
    if (parseFloat(shipping_temp) < 9) return 'ДА'
    if (shipping_temp.indexOf('-')>=0) {
      if (parseFloat(shipping_temp.split('-')[0]) < 9) return 'Да'
      return 'НЕТ'
    } else {
      return 'НЕТ'
    }
  } catch (e) {
    return 'НЕТ'
  }
}

export default class ExportToXlsx {
  dataForExcel:Object[] = []

  constructor(data: []){
    data.forEach((product: productInterface, index)=> {
      let qua = product.quantity.toLocaleLowerCase()
      if (qua.indexOf("x") >= 0) {
        qua = (parseFloat(qua.split("x")[0]) * parseFloat(qua.split("x")[1])).toLocaleString()
      }

      this.dataForExcel.push({
        Supplier: 'Helen',
        SKU: product.sku.toLocaleUpperCase(),
        Item: product.item,
        Purity: purity(product.purity),
        'Lead-time': lead_time(product.leadtime),
        'Срок поставки': leadtime_converter(product.leadtime, product.manufacturer),
        CAS: cas(product.cas),
        Manufacturer: product.manufacturer.replace('CST', 'Cell Signaling Technology'),
        Quantity: quantity(qua),
        Unit: unit(product.unit),
        'Shipping temp (storage)': shipping_temp(product.shipping_temp),
        'Тип - Холодовая химия': check_is_cold(product.shipping_temp),
        'Price amount': product.price || '',
        'Price units': product.price_unit || '',
        'Danger': product.danger ? "ДА" : 'Non chemical',
        'Formula': `${product.sku.toLocaleUpperCase()}∩${cas(product.cas)}∪${product.manufacturer.replace('CST', 'Cell Signaling Technology')}⊂Helen⊃${product.item}⊄${leadtime_converter(product.leadtime, product.manufacturer)}⊅${product.price || ''}⊆${product.price_unit}⊇${purity(product.purity)}∏${product.shipping_temp}∐${quantity(qua)}⨒${unit(product.unit)}⨗`
      })
    })
  }

  exportToFile(){
    const ws = utils.json_to_sheet(this.dataForExcel)
    ws["!cols"] = [
      {wch: 8},
      {wch: 15},
      {wch: 20},
      {wch: 10},
      {wch: 15},
      {wch: 30},
      {wch: 10},
      {wch: 10},
      {wch: 8},
      {wch: 8},
      {wch: 15},
      {wch: 10},
      {wch: 10},
      {wch: 10},
      {wch: 15},
      {wch: 100}
    ];
    const wb = utils.book_new()
    utils.book_append_sheet(wb, ws, 'Лист 1')
    writeFileXLSX(wb,  'products.xlsx')
  }
}